import { Link } from "react-router-dom";
import "../styles/Ecosystem.scss";


const ecosystemArray = [
    {
        header: "BlockStar Defi",
        ribbion: "Defi",
        class: "defi-hover",
        span: "span-crypto",
        description: 'BlockStar’s Defi ecosystem offers one-of-a-kind infrastructure: Mainnet and Testnet environments, DEX trading, staking, NFTs, DAO governance, and more. Your future in decentralized finance starts here.',
        link: "/defi",
        is_new: 2,
        link_type: 2
    },
    {
        header: "BlockStar Hex",
        ribbion: "Hex",
        class: "hex-hover",
        span: "span-hex",
        description: 'Experience the best of both worlds with our secure hybrid exchange, where you can trade with the convenience of a centralized exchange while enjoying the transparency of a public ledger and retaining custody of your cryptocurrency assets in your own DeFi wallet.',
        link: "https://blockstarexchange.com/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Altitude",
        ribbion: "Altitude",
        class: "altitude-hover",
        span: "span-crypto",
        description: 'The home of everything blockchain in one easy to manage platform. Interact with your entire crypto portfolio decentralized, and in one place. Buy, sell, stake, and make trades cross-chain. NFT marketplace. Project launchpad.',
        link: "https://blockstar.site/portfolio/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Markets",
        ribbion: "Markets",
        class: "markets-hover",
        span: "span-utility",
        description: 'BlockStar Markets provides access to real-time quotes, interactive charts, trades, breaking news, and social media posts for over 2,000 digital assets. Additional tools include portfolio tracking for current values and profit/loss percentages, and an easy-to-use cryptocurrency conversion calculator.',
        link: "https://markets.blockstar.site/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Chain",
        ribbion: "Chain",
        class: "chain-hover",
        span: "span-white",
        description: 'BlockStar Chain is the backbone of our ecosystem, powering seamless connections across Mainnet, Testnet, and beyond. Engineered for speed, security, and scalability, it’s the foundation for decentralized innovation and next-generation applications.',
        link: "/chain",
        is_new: 2,
        link_type: 2
    }
];


export default function Finance() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col">
                    <div className="social">
                        {ecosystemArray.map((item, index) => {
                            return (
                                item.link_type === 2 ? (
                                    <Link
                                        to={item.link}
                                        className={item.class}
                                        key={index}
                                    >
                                        <div class="ribbon ribbon-top-left">
                                            <span className={item.span}>{item.ribbion}</span>
                                        </div>
                                        <p>{item.description}</p>
                                    </Link>
                                ) : (


                                    <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={item.class}
                                        key={index}
                                    >
                                        <div class="ribbon ribbon-top-left">
                                            <span className={item.span}>{item.ribbion}</span>
                                        </div>
                                        <p>{item.description}</p>
                                    </a>
                                )
                            );
                        })}
                    </div>
                </div>

            </article>
        </main>
    )
}
