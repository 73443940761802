import "../styles/Ecosystem.scss";



const ecosystemArray = [
    {
        header: "BlockStar Daisi",
        ribbion: "daisi",
        class: "daisi-hover",
        span: "span-daisi",
        description: 'Discover the future of AI with the advanced technology of DAISI, "Decentralized Artificial Intelligence Support Interface". This cutting-edge tool offers endless possibilities. Join us and experience the remarkable power of DAISI for yourself!',
        link: "https://blockstar.software/",
        is_new: 2,
        link_type: 1
    },
    
    {
        header: "BlockStar Agency",
        ribbion: "Agency",
        class: "services-hover",
        span: "span-utility",
        description: 'Smart solutions for real people. With expertise in fields such as design, writing, social media and more, our team can provide you with smart solutions that generate real results.',
        link: "https://blockstar.services/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "Smart Animal",
        ribbion: "Smart Animal",
        class: "kids-hover",
        span: "span-daisi",
        description: 'Explore BlockStar Animals, where kids can embark on digital adventures for learning. Interactive stories and games ignite creativity in a safe online world. The ultimate fun destination for the youngest enthusiasts!',
        link: "https://blockstar.kids/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "AI-Agents",
        ribbion: "AI-Agents",
        class: "ai-agent",
        span: "span-daisi",
        description: 'Welcome to the future of intelligent assistance! AI Agents, tailored for every need! Our team of highly specialized AI Agents are ready to help you with a wide range of tasks, no matter the challenge.',
        link: "https://blockstar.vision/",
        is_new: 2,
        link_type: 1
    }
];


export default function Ai() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col">
                    <div className="social">
                        {ecosystemArray.map((item, index) => {
                            return (
                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={item.class}
                                    key={index}
                                >
                                    <div class="ribbon ribbon-top-left">
                                        <span className={item.span}>{item.ribbion}</span>
                                    </div>
                                    {/* {item.is_new === 1 && */}
                                    {/* <div class="ribbon ribbon1  ribbon-bottom-right">
                                        <span className={item.span}></span>
                                    </div> */}
                                    {/* } */}
                                    <p>{item.description}</p>
                                </a>
                            );
                        })}
                    </div>
                </div>
            </article>
        </main>
    )
}
